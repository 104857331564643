<template>
  <div></div>
</template>

<script>
export default {
  methods: {
    findindexFn({ arr, prop, key }) {
      if (prop) {
        return arr.findIndex(item => item[prop] == key);
      } else {
        return arr.findIndex(item => item == key);
      }
    },
    messageFn({ text, type, offset, duration }) {
      this.$message({
        message: text,
        type: type,
        offset: offset ? offset : 20,
        duration: duration ? duration : 3000
      });
    },
    confirmDel(cusDelTipText, dialogTitle, delApiFn, cancelDelTipText) {
      this.$confirm(cusDelTipText, dialogTitle, {
        dangerouslyUseHTMLString: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 调用接口删除操作
          delApiFn();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: cancelDelTipText
          });
        });
    },
    // 批量
    batchOperate(multipleSelection, operateFn, noMulDataTipText) {
      if (multipleSelection.length >= 1) {
        operateFn();
      } else {
        this.$message({
          type: "warning",
          message: noMulDataTipText
        });
      }
    },
    // 数组里嵌套对象的去重(需要去重的原数组oldArr，按照哪个key去重)
    FnRemoveDuplicate(oldArr, key) {
      let hash = {};
      let arr = oldArr.reduce(function(item, next) {
        hash[next[key]] ? "" : (hash[next[key]] = true && item.push(next));
        return item;
      }, []);
      return arr;
    },
  }
};
</script>

<style>
</style>