<template>
  <el-container class="pr">
    <el-tabs
      v-model="activeTab"
      @tab-click="handleTabClick"
      class="flex-1 cus-tab-wrap dp-flex flex-d-c pr"
      type="border-card"
    >
      <el-tab-pane label="广告帐号" name="account">
        <!--   @need-sync="needSync" -->
        <ad-account-list
          :isSync="syncObj == 'account'"
          v-if="activeTab == 'account'"
          @topixelpage="handleToPixelPage"
        ></ad-account-list>
      </el-tab-pane>
      <el-tab-pane label="像素" name="pixel">
        <pixel-list
          :isSync="syncObj == 'pixel'"
          v-if="activeTab == 'pixel'"
          :id="id"
        ></pixel-list>
      </el-tab-pane>
      <el-tab-pane label="主页" name="page">
        <main-page-list :isSync="syncObj == 'page'" v-if="activeTab == 'page'">
        </main-page-list>
      </el-tab-pane>
      <!-- <el-tab-pane label="商务管理平台" name="bm">
        <business-manage :isSync="syncObj == 'bm'" v-if="activeTab == 'bm'">
        </business-manage>
      </el-tab-pane>-->
      <el-tab-pane label="主体管理" name="entity">
        <entity-manage
          :isSync="syncObj == 'entity'"
          v-if="activeTab == 'entity'"
        >
        </entity-manage>
      </el-tab-pane> 
    </el-tabs>
    <!-- 同步 -->
    <div class="dp-flex align-item-c pa" style="top:0;right:0;">
      <div>
        <p class="text-a-r small-gray">授权Facebook账号：</p>
        <p class="text-a-r f-14">
          <strong>{{ user.name }}</strong>
          <span style="font-size: 90%;color: rgb(98, 100, 102);"
            >({{ user.email }})</span
          >
        </p>
      </div>
      <el-tooltip
        effect="dark"
        content="同步广告帐号，像素，主页，商务管理平台，主体"
        placement="top"
      >
        <i
          @click="handleSync"
          class="el-icon-refresh-left f-16 color-theme cursor-pointer ml-10"
        ></i>
      </el-tooltip>
    </div>
  </el-container>
</template>

<script>
import adAccountList from "./components/adAccountList";
import pixelList from "./components/pixelList";
import mainPageList from "./components/mainPageList";
import businessManage from "./components/businessManage";
import entityManage from "./components/entityManage";
import { synBM } from "@/api/adAssets";
import { getUsers } from "@/api/autoRules";
export default {
  components: {
    adAccountList,
    pixelList,
    mainPageList,
    businessManage,
    entityManage
  },
  data() {
    return {
      // tab
      activeTab: "account",
      user: {
        // name: "Qi Bi", email: "yangzongxun@dingtalk.com"
      },
      id: "",
      // 同步对象
      syncObj: "",
      // 登录时fb权限为true，fbaccountnum为0时，强制同步
      forceSync: false
    };
  },
  methods: {
    getUsers() {
      this.$showLoading();
      getUsers().then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          this.user = res.data;
        }
      });
    },
    // 广告资产同步
    synBM() {
      this.$showLoading();
      synBM({ type: this.activeTab }).then(res => {
        this.$hideLoading();
        if (res.code == 200) {
          // this.$message.success(`“${this.activeTab}”同步成功！`);
          if (!this.forceSync) {
            this.$message.success(res.comment);
          }
          this.syncObj = this.activeTab;
        }
      });
    },
    // 同步
    handleSync() {
      if (this.activeTab != "bm" && this.activeTab != "entity") {
        if (this.activeTab == "account") {
          this.$bus.$emit("trigger-synBM")
        } else {
          this.synBM();
        }
      } else {
        this.$message("开发中，敬请期待！");
      }
    },
    // 点击tab
    handleTabClick(e) {
      // console.log("handleTabClick(e)", e);
      this.id = "";
    },
    // 点击像素-像素页面
    handleToPixelPage(scope) {
      this.activeTab = "pixel";
      this.id = scope.row.id;
    },
    needSync() {
      this.forceSync = true;
      this.synBM();
    }
  },
  mounted() {
    this.getUsers();
  }
};
</script>

<style lang='scss'>
@import "@/styles/commonly";
.cus-tab-wrap {
  width: 100%;
  .el-tabs__item {
    color: rgb(81, 81, 81);
    padding: 0 16px;
    font-size: 17px;
    border-radius: 6px 6px 0 0;
    border: none;
    background: rgb(231, 232, 235);
    margin-right: 8px;
    font-weight: 400;
    text-align: left;
    min-width: 176px;
  }
  &.el-tabs--border-card {
    border: none;
    box-shadow: none;
    & > .el-tabs__header {
      @extend .bg-theme;
      border-bottom: none;
    }
  }
  .el-tabs__content {
    @extend .flex-1;
    padding: 8px 16px 16px;
  }
}
</style>
