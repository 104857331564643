<template>
  <el-form
    :inline="formDatas.inline"
    ref="formRef"
    :rules="formRules"
    :model="formModel"
    :size="formDatas.size"
    :label-position="formDatas.labelPositon"
  >
    <el-form-item
      v-for="(item, index) in formItemDatas"
      :key="index"
      :label="item.label"
      :prop="item.prop"
    >
      <el-input
        v-if="item.type == 'input'"
        v-model="formModel[item.prop]"
        :placeholder="item.placeholder"
        clearable
      >       
      </el-input>      
      <el-select
        class="w-100"
        v-if="item.type == 'select'"
        v-model="formModel[item.prop]"
        :placeholder="item.placeholder"
        filterable
        clearable
      >
        <el-option
          v-for="(opt, optIndex) in item.options"
          :key="optIndex"
          :label="item.optLabel ? opt[item.optLabel] : opt.name"
          :value="item.value ? opt[item.value] : opt.id"
        ></el-option>
      </el-select>
      <el-switch
        v-if="item.type == 'switch'"
        v-model="formModel[item.prop]"
        :active-color="item.activeColor ? item.activeColor : '#409EFF'"
        :inactive-color="item.inactiveColor ? item.inactiveColor : '#C0CCDA'"
        :active-value="1"
        :inactive-value="0"
      ></el-switch>
    </el-form-item>
    <el-form-item v-if="showSubmit">
      <el-button type="primary" @click="submitForm('formRef')">查询</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
    formDatas: {
      type: Object,
      default: () => {
        return { inline: true, size: "—", labelPositon: "top" };
      }
    },
    formItemDatas: {
      type: Array,
      default: () => []
    },
    formModelFn: {
      type: Object,
      default: () => {}
    },
    formRulesFn: {
      type: Object,
      default: () => {}
    },
    showSubmit: {
      type: Boolean,
      default: () => false
    },
    initModel: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      formModel: this.initModel || this.formModelFn,
      formRules: this.formRulesFn
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.$emit("submit-form", this.formModel);
        } else {
          return false;
        }
      });
    }
  },
  watch: {
    initModel: {
      handler(val) {
        // console.log("1-watch-initModel", val);
        if (val) {
          // console.log("2-watch-initModel", val);
          this.formModel = { ...val };
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style>
</style>